import React, { createContext, useContext, useEffect, useState } from "react";
import { useServer } from "./Server";

// Create the BrandContext with the data type specified
// and a empty object
const BrandContext = createContext();

const BrandContextProvider = ({ children }) => {
  const [brandContextData, setBrandContextData] = useState();
  const [loadingBrandContextData, setLoadingBrandContextData] = useState(true);
  const { serverData } = useServer();

  useEffect(() => {
    fetchMerchantData();
  }, []);

  const fetchMerchantData = () => {
    let staticBrandContextObj;

    if (process.env.REACT_APP_BUILD_MODE === "local") {
      staticBrandContextObj = require("../brand-context-ids.json");
    }

    let staticContext;
    if (
      process.env.REACT_APP_BUILD_MODE &&
      process.env.REACT_APP_BUILD_MODE === "local"
    ) {
      staticContext =
        staticBrandContextObj[process.env.REACT_APP_CONTEXT_CLIENT];
    } else {
      staticContext = process.env.REACT_APP_STATIC_CONTEXT;
      staticContext = JSON.parse(staticContext);
    }

    setBrandContextData(staticContext);
    setLoadingBrandContextData(false);
  };

  useEffect(() => {
    if (
      serverData &&
      brandContextData &&
      !brandContextData?.squareData &&
      !brandContextData?.stadiumListing
    ) {
      console.log("Attach sync");
      attachSync();
    }
  }, [brandContextData, serverData]);

  const getStadiumListing = async () => {
    let stadiumListing = [];
    try {
      stadiumListing = await serverData.call("USSR_listStoresWithFilters", {
        contextId: brandContextData._id,
      });
    } catch (e) {
      console.log("Error getting stadiumListing", e);
    }
    setBrandContextData({
      ...brandContextData,
      stadiumListing: stadiumListing,
    });
  };

  const attachSync = async () => {
    let squareData = null;
    try {
      squareData = await serverData.call("MIKO_getSquareVariables", {
        contextId: brandContextData._id,
      });
    } catch (e) {
      console.log("Error getting squareData", e);
      return;
    }

    let fundraisingCampaigns = [];
    try {
      const donationCampaignsRes = await serverData.call(
        "MIKO_getFundraisingCampaigns",
        {
          contextId: brandContextData._id,
        }
      );

      fundraisingCampaigns = donationCampaignsRes?.fundraisingCampaigns
        ? donationCampaignsRes?.fundraisingCampaigns
        : [];
    } catch (e) {
      console.log("Error getting donationCampaignsRes", e);
    }

    let adSpaces = [];
    try {
      const adSpacesRes = await serverData.call("MIKO_getAdSpaces", {
        contextId: brandContextData._id,
      });
      adSpaces = adSpacesRes?.adSpaces ? adSpacesRes.adSpaces : [];
    } catch (e) {
      console.log("Error getting adspaces", e);
    }

    let announcements = [];
    try {
      const announcementsRes = await serverData.call("MIKO_getAnnouncements", {
        contextId: brandContextData._id,
      });
      announcements = announcementsRes?.brandAnnouncements
        ? announcementsRes.brandAnnouncements
        : [];
    } catch (e) {
      console.log("Error getting announcements", e);
    }

    let accountUserInfo = {};
    let enableAccounts = false;
    try {
      const accountInfoRes = await serverData.call("MIKO_getAccountsInfo", {
        contextId: brandContextData._id,
      });
      if (accountInfoRes) {
        accountUserInfo = accountInfoRes.accountUserInfo;
        enableAccounts = accountInfoRes.enableAccounts;
      }
    } catch (e) {
      console.log("Error getting accouts info", e);
    }

    setBrandContextData({
      ...brandContextData,
      fundraisingCampaigns: fundraisingCampaigns,
      adSpaces: adSpaces,
      announcements: announcements,
      squareData: squareData,
      accountUserInfo,
      enableAccounts,
    });
  };

  return (
    //This component will be used to encapsulate the whole App,
    //so all components will have access to the Context
    <BrandContext.Provider
      value={{
        fetchMerchantData,
        brandContextData,
        loadingBrandContextData,
        setBrandContextData,
        getStadiumListing,
      }}
    >
      {children}
    </BrandContext.Provider>
  );
};

// A simple hooks to facilitate the access to the BrandContext
// and permit components to subscribe to BrandContext updates
function useBrandContext() {
  const context = useContext(BrandContext);

  if (!context) {
    throw new Error(
      "useBrandContext must be used within a BrandContextProvider"
    );
  }

  return context;
}

export { BrandContext, BrandContextProvider, useBrandContext };
