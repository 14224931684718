import React, { useEffect } from "react";
import { Row, Col, Input, Typography } from "antd";
import {
  userAccountInfoValidation,
  validateCanadianPostalCode,
  validateEmail,
} from "../const/const_functions";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

export const DonateInfoSection = (props) => {
  const { Text } = Typography;
  const {
    firstName,
    setFirstNameError,
    setFirstName,
    setLastNameError,
    setPhone,
    setEmailError,
    setLastName,
    setEmail,
    setPhoneError,
    firstNameError,
    lastNameError,
    lastName,
    email,
    emailError,
    phone,
    phoneError,
    address,
    setAddress,
    mobile,
    googleAPIKey,
  } = props;

  const onChangeFirstName = (value) => {
    if (value) {
      if (userAccountInfoValidation(value, "isName") !== true) {
        setFirstNameError(true);
        setFirstName(value);
      } else {
        setFirstNameError(false);
        setFirstName(value);
      }
    } else {
      setFirstNameError(false);
      setFirstName("");
    }
  };

  const onChangeLastName = (value) => {
    if (value) {
      if (userAccountInfoValidation(value, "isName") !== true) {
        setLastNameError(true);
        setLastName(value);
      } else {
        setLastNameError(false);
        setLastName(value);
      }
    } else {
      setLastNameError(false);
      setLastName("");
    }
  };

  const onChangeEmail = (value) => {
    if (value) {
      if (!validateEmail(value)) {
        setEmailError(true);
        setEmail(value);
      } else {
        setEmailError(false);
        setEmail(value);
      }
    } else {
      setEmailError(false);
      setEmail("");
    }
  };

  const onChangePhone = (value) => {
    if (value) {
      if (userAccountInfoValidation(value, "isPhone") !== true) {
        setPhoneError(true);
        setPhone(value);
      } else {
        setPhoneError(false);
        setPhone(value);
      }
    } else {
      setPhoneError(false);
      setPhone("");
    }
  };

  return (
    <>
      <Row className="rowTitle">
        <Text className="textTitle">Your Information</Text>
      </Row>
      <Row gutter={6}>
        <Col span={12}>
          <Input
            placeholder="First Name"
            value={firstName}
            onChange={(e) => onChangeFirstName(e.target.value)}
          />
          {firstNameError ? (
            <Text className="errorText">First name is too short.</Text>
          ) : null}
        </Col>
        <Col span={12}>
          <Input
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => onChangeLastName(e.target.value)}
          />
          {lastNameError ? (
            <Text className="errorText">Last name is too short.</Text>
          ) : null}
        </Col>
      </Row>
      <Row className="infoInputRow" gutter={6}>
        <Col span={24}>
          <Input
            placeholder="E-mail"
            value={email}
            onChange={(e) => onChangeEmail(e.target.value)}
          />
          {emailError ? (
            <Text className="errorText">The input is not valid E-mail.</Text>
          ) : null}
        </Col>
      </Row>
      <Row className="infoInputRow" gutter={6}>
        <Col span={24}>
          <Input
            placeholder="Phone Number (Optional)"
            value={phone}
            onChange={(e) => onChangePhone(e.target.value)}
            type="number"
          />
          {phoneError ? (
            <Text className="errorText">Please enter a valid phone number</Text>
          ) : null}
        </Col>
      </Row>
      <Row className="rowTitle" style={{ marginBottom: 0 }}>
        <Text className="textTitle">Address Info</Text>
      </Row>
      <Row>
        <Text style={{ color: "gray" }}>Used for tax receipt purposes</Text>
      </Row>
      <Row className="infoInputRow" gutter={6}>
        <Col span={24}>
          <GooglePlacesAutocomplete
            apiKey={googleAPIKey}
            onLoadFailed={(error) => {
              console.log("Could not inject Google Script", error);
            }}
            selectProps={{
              placeholder: "Enter your address",
              height: 50,
              onChange: setAddress,
              address,
              styles: {
                input: (provided) => ({
                  ...provided,
                  paddingTop: mobile ? 0 : 10,
                  paddingBottom: 10,
                }),
              },
            }}
          />
        </Col>
      </Row>
    </>
  );
};
